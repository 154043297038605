import { FunctionComponent } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

import { scoreColor } from "../helpers/analytics";

const ScoreCell: FunctionComponent<{score: number|undefined}> = ({score}) => {

  return score ? (
    <td
      style={{
        background: scoreColor(score),
        color: "black",
      }}
    >
      <FormattedNumber
        value={score}
        signDisplay="always"
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
      &nbsp;
      <FormattedMessage
        id="module.lector.analyticsAreas.points"
        defaultMessage="points"
      />
      {score > 3.9 ? (
        <i className="icon">🌟</i>
      ) : (
        score > 3.6 && <i className="icon">⭐</i>
      )}
    </td>
  ) : (
    <td>
      <>-</>
    </td>
  )
}

export default ScoreCell;