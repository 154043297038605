import { useEffect, useState } from "react";

import {
  ModelDimension,
  ModelDimensionType,
  ModelQueryDataEntry,
  ModelQueryMetric,
  StatsModel,
} from "@namedicinu/internal-types";
import useApp from "./useApp";
import { handleError } from "../helpers/error";

const useAnalyticsQuery = <
  M extends StatsModel = StatsModel,
  MD extends ModelDimension<M> = string,
  MQ extends ModelQueryMetric<M> = string,
>(
  options: {
    model: M;
    dimensions: MD[];
    selection: { [K in MD]?: ModelDimensionType<M, K> | ModelDimensionType<M, K>[] };
    queryMetrics: MQ[];
  },
  location: "local" | "remote" | "both",
) => {
  const { model, dimensions, selection, queryMetrics } = options;
  const { user, statsClient } = useApp();
  const [data, setData] = useState<ModelQueryDataEntry<M, MD, MQ>[]>();

  useEffect(() => {
    setData(undefined);
    if (user) {
      if (location == "local") {
        statsClient
          .getLocalStats<M, MD, MQ>(user, model, dimensions, selection, queryMetrics)
          .then(setData)
          .catch(handleError);
      } else if (location == "remote") {
        statsClient
          .getRemoteStats<M, MD, MQ>(model, dimensions, selection, queryMetrics)
          .then(setData)
          .catch(handleError);
      } else {
        statsClient
          .getStats<M, MD, MQ>(user, model, dimensions, selection, queryMetrics)
          .then(setData)
          .catch(handleError);
      }
    }
  }, [user, model, dimensions, selection, queryMetrics]);

  return data;
};

export default useAnalyticsQuery;
