import { IsUUID, IsString, IsOptional } from "class-validator";

export class StudyGroup {
  @IsUUID()
  studyGroupId: string;
  @IsString()
  title: string;
  @IsString()
  termId: string;
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsString()
  lector: string;
  @IsOptional()
  @IsString()
  description: string;
}
