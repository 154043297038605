import { useState, useEffect } from "react";

import useApp from "../../../hooks/useApp";
import { QuizReleaseConfig } from "../../../types";
import { handleError } from "../../../helpers/error";

const useQuizReleases = (
  categoryIds: string[] | undefined,
): { [categoryId: string]: QuizReleaseConfig } | undefined => {
  const { quizClient } = useApp();
  const [quizReleases, setQuizReleases] = useState<{ [categoryId: string]: QuizReleaseConfig }>();

  useEffect(() => {
    if (categoryIds) {
      quizClient.getQuizReleases(categoryIds).then(setQuizReleases).catch(handleError);
    }
  }, [quizClient, categoryIds]);

  return quizReleases;
};

export default useQuizReleases;
