import { FunctionComponent } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import AnalyticsUserAreas from './AnalyticsUserAreas';
import LoadingPlaceholder from '../../../../components/LoadingPlaceholder';
import useApp from '../../../../hooks/useApp';
import AnalyticsUserProgress from './AnalyticsUserProgress';
import AnalyticsUserScore from './AnalyticsUserScore';

const AnalyticsUser: FunctionComponent = () => {
  const { user, content } = useApp();

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <h3>
            <FormattedMessage id="module.user.analytics.user.score" defaultMessage="Score" />
          </h3>
          {(user && content) ? (
            <AnalyticsUserScore userId={user.email} />
          ) : <LoadingPlaceholder />}
        </Col>
        <Col xs={12} md={6}>
          <h3>
            <FormattedMessage id="module.user.analytics.user.progress" defaultMessage="Progress" />
          </h3>
          {(user && content) ? (
            <AnalyticsUserProgress userId={user.email} content={content} />
          ) : <LoadingPlaceholder />}
        </Col>
        <Col xs={12}>
          <h3>
            <FormattedMessage id="module.user.analytics.user.areas" defaultMessage="Areas" />
          </h3>
          {(user && content) ? (
            <AnalyticsUserAreas userId={user.email} content={content} />
          ) : <LoadingPlaceholder />}
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsUser;