import { openDB, deleteDB, IDBPDatabase, IDBPObjectStore, IDBPTransaction } from "idb";
import moment from "moment";

import { JsonObject, JsonType, guid } from "@namedicinu/internal-types";

import { QuizReleaseConfig } from "../types";
import {
  QuizLogEntryJournal,
  QuizSessionJournal,
  UserQuestionModelCache,
  VideoLogEntryJournal,
  VideoSessionJournal,
  LocalStats,
} from "./types";

type DBType = {
  quizReleaseCache: QuizReleaseConfig;
  quizUserQuestionModel: UserQuestionModelCache;
  quizSessionJournal: QuizSessionJournal;
  localStats: LocalStats;
};

export default class LocalDatabase {
  db: IDBPDatabase<DBType> | undefined = undefined;

  async getDb(): Promise<IDBPDatabase<DBType>> {
    if (!this.db) {
      this.db = await openDB("namedicinu", 6, {
        upgrade(db, oldVersion, newVersion) {
          if (!newVersion || oldVersion < newVersion) {
            // reset local database
            for (const store of db.objectStoreNames) {
              db.deleteObjectStore(store);
            }
          }

          // setup new database
          db.createObjectStore("quizReleaseCache", {
            keyPath: "categoryId",
          });

          const quizSessionJournal = db.createObjectStore("quizSessionJournal", {
            keyPath: "localSessionId",
          });
          quizSessionJournal.createIndex("userId", "userId");

          const quizLogJournal = db.createObjectStore("quizLogJournal", {
            autoIncrement: true,
          });
          quizLogJournal.createIndex("localSessionId", "localSessionId");

          db.createObjectStore("quizUserQuestionModelCache", {
            autoIncrement: false,
          });

          const videoSessionJournal = db.createObjectStore("videoSessionJournal", {
            keyPath: "localSessionId",
          });
          videoSessionJournal.createIndex("userId", "userId");
          const videoLogJournal = db.createObjectStore("videoLogJournal", {
            autoIncrement: true,
          });
          videoLogJournal.createIndex("localSessionId", "localSessionId");

          const localStats = db.createObjectStore("localStats", {
            keyPath: "localStatsId",
          });
          localStats.createIndex("modelId", "modelId");
          localStats.createIndex("userId", "userId");
        },
      });
    }
    return this.db;
  }

  async resetDb(): Promise<void> {
    await deleteDB("namedicinu");
    this.db = await this.getDb();
  }

  /**
   * Unilities
   **/

  async getQuizReleaseCache<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizReleaseCache"], Mode>;
    store: IDBPObjectStore<DBType, ["quizReleaseCache"], "quizReleaseCache", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("quizReleaseCache", mode);
    return { tt, store: tt.store };
  }

  async getQuizUserQuestionModelCache<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizUserQuestionModelCache"], Mode>;
    store: IDBPObjectStore<DBType, ["quizUserQuestionModelCache"], "quizUserQuestionModelCache", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("quizUserQuestionModelCache", mode);
    return { tt, store: tt.store };
  }

  async getQuizSessionJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizSessionJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["quizSessionJournal"], "quizSessionJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("quizSessionJournal", mode);
    return { tt, store: tt.store };
  }

  async getQuizLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizLogJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["quizLogJournal"], "quizLogJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("quizLogJournal", mode);
    return { tt, store: tt.store };
  }

  async getQuizSessionAndLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["quizSessionJournal", "quizLogJournal"], Mode>;
    sessionStore: IDBPObjectStore<DBType, ["quizSessionJournal", "quizLogJournal"], "quizSessionJournal", Mode>;
    logStore: IDBPObjectStore<DBType, ["quizSessionJournal", "quizLogJournal"], "quizLogJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction(["quizSessionJournal", "quizLogJournal"] as const, mode);
    return {
      tt,
      sessionStore: tt.objectStore("quizSessionJournal"),
      logStore: tt.objectStore("quizLogJournal"),
    };
  }

  async getVideoSessionJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoSessionJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["videoSessionJournal"], "videoSessionJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("videoSessionJournal", mode);
    return { tt, store: tt.store };
  }

  async getVideoLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoLogJournal"], Mode>;
    store: IDBPObjectStore<DBType, ["videoLogJournal"], "videoLogJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("videoLogJournal", mode);
    return { tt, store: tt.store };
  }

  async getVideoSessionAndLogJournal<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["videoSessionJournal", "videoLogJournal"], Mode>;
    sessionStore: IDBPObjectStore<DBType, ["videoSessionJournal", "videoLogJournal"], "videoSessionJournal", Mode>;
    logStore: IDBPObjectStore<DBType, ["videoSessionJournal", "videoLogJournal"], "videoLogJournal", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction(["videoSessionJournal", "videoLogJournal"] as const, mode);
    return {
      tt,
      sessionStore: tt.objectStore("videoSessionJournal"),
      logStore: tt.objectStore("videoLogJournal"),
    };
  }

  async getLocalStats<Mode extends IDBTransactionMode>(
    mode?: Mode,
  ): Promise<{
    tt: IDBPTransaction<DBType, ["localStats"], Mode>;
    store: IDBPObjectStore<DBType, ["localStats"], "localStats", Mode>;
  }> {
    const db = await this.getDb();
    const tt = db.transaction("localStats", mode);
    return { tt, store: tt.store };
  }

  /**
   * Quiz release
   **/

  async storeQuizRelease(releaseConfig: QuizReleaseConfig): Promise<void> {
    const { tt, store } = await this.getQuizReleaseCache("readwrite");
    await store.put(releaseConfig);
    await tt.done;
  }

  async getQuizReleaseOptional(categoryId: string): Promise<QuizReleaseConfig | undefined> {
    const { store } = await this.getQuizReleaseCache();
    return await store.get(categoryId);
  }

  /**
   * Quiz user question model
   **/

  async storeUserQuestionModel(userQuestionModel: UserQuestionModelCache): Promise<void> {
    const { tt, store } = await this.getQuizUserQuestionModelCache("readwrite");
    await store.put(userQuestionModel, `${userQuestionModel.userId}@${userQuestionModel.categoryId}`);
    await tt.done;
  }

  async getUserQuestionModelOptional(userId: string, categoryId: string): Promise<UserQuestionModelCache | undefined> {
    const { store } = await this.getQuizUserQuestionModelCache();
    return await store.get(`${userId}@${categoryId}`);
  }

  /**
   * Quiz session & entries journal
   */

  async storeQuizSession(session: QuizSessionJournal): Promise<void> {
    const { tt, store } = await this.getQuizSessionJournal("readwrite");
    await store.put(session);
    await tt.done;
  }

  async getQuizSessions(userId: string): Promise<Array<QuizSessionJournal>> {
    const { store } = await this.getQuizSessionJournal();
    return await store.index("userId").getAll(userId);
  }

  async storeQuizLogEntry(logEntry: QuizLogEntryJournal): Promise<void> {
    const { tt, store } = await this.getQuizLogJournal("readwrite");
    await store.put(logEntry);
    await tt.done;
  }

  async storeQuizLogEntries(logEntries: Array<QuizLogEntryJournal>): Promise<void> {
    const { tt, store } = await this.getQuizLogJournal("readwrite");
    for (const logEntry of logEntries) {
      await store.put(logEntry);
    }
    await tt.done;
  }

  async getQuizLogEntries(localSessionId: string): Promise<Array<QuizLogEntryJournal>> {
    const { store } = await this.getQuizLogJournal();
    return await store.index("localSessionId").getAll(localSessionId);
  }

  async clearQuizLog(localSessionId: string): Promise<void> {
    const { tt, sessionStore, logStore } = await this.getQuizSessionAndLogJournal("readwrite");
    sessionStore.delete(localSessionId);
    const logKeys = await logStore.index("localSessionId").getAllKeys(IDBKeyRange.only(localSessionId));
    for (const key of logKeys) {
      await logStore.delete(key);
    }
    await tt.done;
  }

  /**
   * Video session & entries journal
   */

  async storeVideoSession(session: VideoSessionJournal): Promise<void> {
    const { tt, store } = await this.getVideoSessionJournal("readwrite");
    await store.put(session);
    await tt.done;
  }

  async getVideoSessions(userId: string): Promise<Array<VideoSessionJournal>> {
    const { store } = await this.getVideoSessionJournal();
    return await store.index("userId").getAll(userId);
  }

  async storeVideoLogEntry(logEntry: VideoLogEntryJournal): Promise<void> {
    const { tt, store } = await this.getVideoLogJournal("readwrite");
    await store.put(logEntry);
    await tt.done;
  }

  async storeVideoLogEntries(logEntries: Array<VideoLogEntryJournal>): Promise<void> {
    const { tt, store } = await this.getVideoLogJournal("readwrite");
    for (const logEntry of logEntries) {
      await store.put(logEntry);
    }
    await tt.done;
  }

  async getVideoLogEntries(localSessionId: string): Promise<Array<VideoLogEntryJournal>> {
    const { store } = await this.getVideoLogJournal();
    return await store.index("localSessionId").getAll(localSessionId);
  }

  async clearVideoLog(localSessionId: string, keepSession?: boolean): Promise<void> {
    const { tt, sessionStore, logStore } = await this.getVideoSessionAndLogJournal("readwrite");
    if (!keepSession) {
      sessionStore.delete(localSessionId);
    }
    const logKeys = await logStore.index("localSessionId").getAllKeys(IDBKeyRange.only(localSessionId));
    for (const key of logKeys) {
      await logStore.delete(key);
    }
    await tt.done;
  }

  /**
   * Stats
   */
  async storeStatsEntries(userId: string, modelId: string, entries: JsonObject[]): Promise<void> {
    const { tt, store } = await this.getLocalStats("readwrite");
    for (const entry of entries) {
      await store.put({
        userId,
        localStatsId: guid(),
        modelId,
        timestamp: moment().utc().unix(),
        entry,
      });
    }
    await tt.done;
  }

  async getStatsEntries(userId: string, modelId: string): Promise<Array<Record<string, JsonType>>> {
    const todayTimestamp = moment().utc().startOf("day").unix();
    const { store } = await this.getLocalStats();
    const rows = await store.index("modelId").getAll(modelId);
    return rows.filter((entry) => entry.timestamp >= todayTimestamp && entry.userId == userId).map((row) => row.entry);
  }

  async clearOldStatsEntries(userId: string): Promise<void> {
    const todayTimestamp = moment().utc().startOf("day").unix();
    const { tt, store } = await this.getLocalStats("readwrite");
    const rows = await store.index("userId").getAll(userId);
    for (const row of rows) {
      if (row.entry.timestamp < todayTimestamp) {
        await store.delete(row.localStatsId);
      }
    }
    await tt.done;
  }
}
