import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { PlayCircle } from "react-feather";

import VideoWrapper from "./components/VideoWrapper";
import { Module } from "../../types";

const VideoListPage = lazy(() => import("./pages/VideoListPage"));
const VideoPlaybackPage = lazy(() => import("./pages/VideoPlaybackPage"));

export default {
  route: {
    path: "/video",
    element: <VideoWrapper />,
    children: [
      {
        path: "/video",
        index: true,
        element: <VideoListPage />,
      },
      {
        path: "/video/:videoId",
        element: <VideoPlaybackPage />,
      },
    ],
  },
  allowed: (user) => user ? (
    user.isUser() ? (
      user.hasContentAccess(undefined, undefined, "video")
    ) : false
  ) : false,
  name: <FormattedMessage id="module.video.name" defaultMessage="Video" />,
  icon: <PlayCircle />,
} satisfies Module;
