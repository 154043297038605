import { FunctionComponent, ReactNode } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { handleError } from "../helpers/error";

type AppErrorProps = {
  children: ReactNode;
}

const AppError: FunctionComponent<AppErrorProps> = ({ children }) => {

  const fallbackRender = (
    { error, resetErrorBoundary }: FallbackProps
  ) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onError={handleError}
      // onReset={(details) => {
      //   // Reset the state of your app so the error doesn't happen again
      // }}
    >
      {children}
    </ErrorBoundary>
  )
}

export default AppError;