import { Transform } from "class-transformer";
import { IsBoolean, IsEnum, IsOptional, IsString, IsUUID } from "class-validator";
import type { Moment } from "moment";

import { transformMoment } from "../helpers/transforms";
import { IsMoment } from "../helpers/decorators";

export const notificationTypes = ["info", "message", "warning"];
export type NotificationType = (typeof notificationTypes)[number];

export class UserNotification {
  @IsUUID()
  userNotificationId: string;
  @IsString()
  message: string;
  @IsEnum(notificationTypes)
  type: NotificationType;
  @IsOptional()
  @IsString()
  action: string;
  @IsBoolean()
  acknowledged: boolean;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: Moment;
}
