import { IsArray, IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";
import { Transform, Type } from "class-transformer";
import type { Moment } from "moment";

import { transformMoment } from "../helpers/transforms";
import { IsMoment } from "../helpers/decorators";
import { User } from "./user";
import {
  type CourseRegistrationState,
  courseRegistrationStates,
  type LocaleType,
  locales,
  paymentCompanies,
  type PaymentCompany,
} from "../types";

export class CourseRegistration {
  @IsUUID()
  courseRegistrationId: string;
  @IsInt()
  variableSymbol: number;
  @IsEnum(paymentCompanies)
  company: PaymentCompany;
  @IsString()
  productId: string;
  @IsOptional()
  @IsString()
  termId?: string;
  @IsEnum(locales)
  locale: LocaleType;
  @IsOptional()
  @IsString()
  preferredTime?: string;
  @IsOptional()
  @IsString()
  preferredDay?: string;
  @IsOptional()
  @IsString()
  faculty?: string;
  @IsOptional()
  @IsString()
  variation?: string;
  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  subjects?: string[];
  @IsOptional()
  @IsString()
  paymentFrequency?: string;
  @Transform(transformMoment)
  @IsMoment()
  timestamp: Moment;
  @IsEnum(courseRegistrationStates)
  state: CourseRegistrationState;
  @IsOptional()
  @IsString()
  studyGroupId?: string;
}

export class CourseRegistrationWithUser extends CourseRegistration {
  @ValidateNested()
  @Type(() => User)
  user: User;
}
