import { Type } from "class-transformer";
import { IsUUID, IsString, IsArray, IsBoolean, IsNumber, IsOptional, ValidateNested } from "class-validator";

class Range {
  @IsNumber()
  from: number;
  @IsNumber()
  to: number;
}

export class QuizSelection {
  @IsUUID()
  quizSelectionId: string;
  @IsString()
  categoryId: string;
  @IsString()
  modeId: string;
  @IsArray()
  @IsString({ each: true })
  topicIds: Array<string>;
  @IsBoolean()
  showAnswers: boolean;
  @IsBoolean()
  shuffleAnswers: boolean;
  @IsBoolean()
  singleChoice: boolean;
  @IsBoolean()
  allQuestionsAtOnce: boolean;
  @IsBoolean()
  allowQuestionReminders: boolean;
  @ValidateNested()
  @Type(() => Range)
  selectQuestionsRange: Range;
  @IsOptional()
  @IsArray()
  @IsString({ each: true })
  selectQuestionAreas: [];
  @IsNumber()
  questionsPerQuiz: number;
  @IsBoolean()
  questionsPreddefined: boolean;
  @IsNumber()
  answersToChooseFrom: number;
  @IsOptional()
  @IsNumber()
  timer: null | number;
}
