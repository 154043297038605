import * as yup from "yup";
import { ValidationOptions, ValidationArguments, buildMessage, ValidateBy, ValidateIf } from "class-validator";
import type { ValidationMetadata } from "class-validator/types/metadata/ValidationMetadata";
import moment from "moment";
import type { SchemaObject } from "openapi3-ts";

import Localized from "./localized";
import { locales, LocaleType } from "../types";

export function IsYup(schema: yup.AnySchema, validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isYup",
      constraints: [schema],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [schema] = args.constraints;
          try {
            (schema as yup.AnySchema).validateSync(value, {
              strict: true,
              stripUnknown: true,
            });
            return true;
          } catch (error) {
            return false;
          }
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must pass inner schema",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsMoment(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isMoment",
      validator: {
        validate: (value): boolean => moment.isMoment(value),
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be a Moment instance",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsMomentDate(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isMomentDate",
      validator: {
        validate: (value): boolean => {
          if (!moment.isMoment(value)) return false;
          return moment(value).startOf("day").diff(value) == 0 || moment(value).endOf("day").diff(value) == 0;
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be a Moment instance",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsDuration(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isDuration",
      validator: {
        validate: (value): boolean => moment.isDuration(value),
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be a Duration instance",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export function IsOptionalString(validationOptions?: ValidationOptions) {
  return ValidateIf((_object, value) => value !== null && value !== undefined && value !== "", validationOptions);
}

export const isLocalized = (value: any, args: ValidationArguments | undefined): value is Localized<any> => {
  if (!(value instanceof Localized)) return false;
  if (args && args.object) {
    const locales = (args.object as any)["locales"] as LocaleType[] | undefined;
    if (locales) {
      for (const locale of locales) {
        if (!value.has(locale)) return false;
      }
    }
  }
  return true;
};

export function IsLocalized(schema: SchemaObject, validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: "isLocalized",
      constraints: [schema],
      validator: {
        validate: (value, args) => {
          return isLocalized(value, args);
        },
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + "$property must be an Localized instance",
          validationOptions,
        ),
      },
    },
    validationOptions,
  );
}

export const additionalConverters = {
  isMoment: {
    type: "string",
    format: "date-time",
  } satisfies SchemaObject,
  isMomentDate: {
    type: "string",
    format: "date",
  } satisfies SchemaObject,
  isDuration: {
    type: "string",
    format: "time",
  } satisfies SchemaObject,
  isLocalized: (meta: ValidationMetadata): SchemaObject =>
    ({
      type: "object",
      properties: Object.fromEntries(locales.map((locale) => [locale, meta.constraints[0]])),
    }) satisfies SchemaObject,
};
