import { FunctionComponent, useState, Suspense, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate, useNavigationType } from "react-router-dom";
import clsx from "clsx";

import { modules } from "../../modules";
import Header from "./Header";
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import Unauthorized from "../Unauthorized";
import useApp from "../../hooks/useApp";

const Layout: FunctionComponent = () => {
  const { user, content, colorScheme } = useApp();
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const [sidebar, setSidebar] = useState(true);
  
  useEffect(() => {
    if (navigationType == "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname, navigationType]);

  useEffect(() => {
    if (user === null) {
      navigate("/login");
    }
  }, [user]);

  const module = modules.find((module) => (
    pathname == module.route.path ||
    pathname.startsWith((module.route.path || "") + "/")
  ));
  const authorizing = user === undefined || content === undefined;
  const authorized = module ? module.allowed(user, content) : !!user;
  
  return (
    <>
      <div className={clsx("page-wrapper", colorScheme)}>
        <div className="page-body-wrapper">
          <Header sidebar={sidebar} setSidebar={setSidebar} />
          <Sidebar sidebar={!authorizing && sidebar} setSidebar={setSidebar} />
          <div className="page-body">
            {authorizing ? (
              <Loader />
            ) : (
              authorized ? (
                <Suspense fallback={<Loader />}>
                  <Outlet />
                </Suspense>
              ) : (
                <Unauthorized />
              )
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Layout;
