import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { User } from "react-feather";

import UserWrapper from "./components/UserWrapper";
import UserAnalyticsPage from "./pages/UserAnalyticsPage";
import { Module } from "../../types";

const UserPage = lazy(() => import("./pages/UserPage"));
const UserCoursesPage = lazy(() => import("./pages/UserCoursesPage"));
const UserSettingsPage = lazy(() => import("./pages/UserSettingsPage"));

export default {
  route: {
    path: "/user",
    element: <UserWrapper />,
    children: [
      {
        index: true,
        path: "/user",
        element: <UserPage />,
      },
      {
        path: "/user/courses",
        element: <UserCoursesPage />,
      },
      {
        path: "/user/settings",
        element: <UserSettingsPage />,
      },
      {
        path: "/user/analytics",
        element: <UserAnalyticsPage />,
      }
    ]
  },
  allowed: () => true,
  name: <FormattedMessage id="module.user.name" defaultMessage="User" />,
  icon: <User />,
} satisfies Module;
