import { FunctionComponent } from 'react';

import useApp from '../hooks/useApp';

const Category: FunctionComponent<{
  categoryId: string;
}> = ({ categoryId }) => {
  const { content } = useApp();
  
  return (
    <>
      {content.categories.find((category) => category.categoryId === categoryId)?.title || categoryId}
    </>
  )
}

export default Category;