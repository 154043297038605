import { IsEnum, IsInt, IsOptional, IsString, IsUUID, ValidateNested } from "class-validator";
import { type Moment } from "moment";
import { Transform, Type } from "class-transformer";

import { IsMoment } from "../helpers/decorators";
import { transformMoment } from "../helpers/transforms";

export const targetTypes = ["global", "quiz", "video"] as const;
export type TargetType = (typeof targetTypes)[number];

export const reportStates = ["new", "acknowledged", "resolved"] as const;
export type ReportState = (typeof reportStates)[number];

export class IssueTargetQuiz {
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsInt()
  questionNumber: number;
}

export class IssueTargetVideo {
  @IsString()
  categoryId: string;
  @IsString()
  topicId: string;
  @IsString()
  videoId: string;
}

export class IssueTargetStudyMaterial {
  @IsString()
  studyGroupId: string;
  @IsString()
  studyMaterialId: string;
}

export class IssueReport {
  @IsUUID()
  issueReportId: string;
  @IsString()
  reporterId: string;
  @IsString()
  clientId: string;
  @IsEnum(targetTypes)
  targetType: TargetType;
  @IsOptional()
  @ValidateNested()
  @Type(() => IssueTargetQuiz)
  targetQuiz: IssueTargetQuiz;
  @IsOptional()
  @ValidateNested()
  @Type(() => IssueTargetVideo)
  targetVideo: IssueTargetVideo;
  @IsOptional()
  @ValidateNested()
  @Type(() => IssueTargetStudyMaterial)
  targetStudyMaterial: IssueTargetStudyMaterial;
  @IsString()
  message: string;
  @IsEnum(reportStates)
  state: ReportState;
  @Transform(transformMoment)
  @IsMoment()
  createdAt: Moment;
  @Transform(transformMoment)
  @IsMoment()
  updatedAt: Moment;
}
