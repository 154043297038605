
import { FunctionComponent, useMemo } from "react";
import { Button, Table } from "reactstrap";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import slugify from "slugify";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-feather";

import { ModelDimension, ModelQueryMetric, UserContent, quizQuestionModel } from "@namedicinu/internal-types";

import useAnalyticsQuery from "../../../../hooks/useAnalyticsQuery";
import LoadingPlaceholder from "../../../../components/LoadingPlaceholder";
import useQuizReleases from "../../../quiz/hooks/useQuizReleases";
import Category from "../../../../components/Category";
import Topic from "../../../../components/Topic";
import { aggregateHigherLevelStats } from "../../../../helpers/analytics";
import ScoreCell from "../../../../components/ScoreCell";

type AnalyticsAreasType = {
  userId: string,
  content: UserContent;
};

const AnalyticsUserAreas: FunctionComponent<AnalyticsAreasType> = ({
  userId, content,
}) => {
  const categoryIds = useMemo(() => (
    content.categories.map((category) => category.categoryId)
  ), [content]);
  const query = useMemo(() => {
    return {
      model: quizQuestionModel,
      dimensions: (
        ["categoryId", "topicId", "area"]
      ) as ModelDimension<typeof quizQuestionModel>[],
      selection: {
        "email": userId
      },
      queryMetrics: (
        ["avg-score"]
       ) as ModelQueryMetric<typeof quizQuestionModel>[],
    };
  }, [userId]);
  const data = useAnalyticsQuery(query, "both");
  const quizReleases = useQuizReleases(categoryIds);
  const levelData = useMemo(() => {
    if(data && quizReleases) {
      return aggregateHigherLevelStats(data, content, "numeric", quizReleases);
    }
    return undefined;
  }, [data, quizReleases]);
  
  return levelData ? (
    <Table className="score-overview">
      <thead>
        <tr>
          <th>
            <FormattedMessage
              id="module.user.analytics.areas.title"
              defaultMessage="Category / Topic / Area"
            />
          </th>
          <th>
            <FormattedMessage
              id="module.user.analytics.areas.avgScore"
              defaultMessage="Avg score"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {levelData.map(({categoryId, categoryAvgScore, topics}, categoryIndex) => [
          <tr
            key={`c-${categoryId}`}
            className={clsx("h1", {"pt-5": categoryIndex > 0})}
          >
            <td>
              <Category categoryId={categoryId} />
            </td>
            <ScoreCell score={categoryAvgScore} />
            <td></td>
          </tr>,
          ...topics.map(({topicId, topicAvgScore, areas}, topicIndex) => [
              <tr
                key={`c-${categoryId}-${topicId}`}
                className={clsx("h3", {"pt-3": topicIndex > 0})}
              >
                <td>
                  <Topic categoryId={categoryId} topicId={topicId} />
                </td>
                <ScoreCell score={topicAvgScore} />
                <td>
                  <Button tag={Link} to={`/lector/questions/${categoryId}/${topicId}`} size="md">
                    <FormattedMessage id="module.user.analytics.areas.questions" defaultMessage="Questions" />
                    <> </>
                    <ArrowRight size={14} />
                  </Button>
                </td>
              </tr>,
              ...areas.map(({area, areaAvgScore}) => (
                  <tr
                    key={`c-${categoryId}-${topicId}-${area}`}
                    className="h6"
                  >
                    <td>
                      {area}
                    </td>
                    <ScoreCell score={areaAvgScore} />
                    <td>
                      <Button tag={Link} to={`/lector/questions/${categoryId}/${topicId}/${slugify(
                        area, {replacement: "-", lower: true}
                      )}`} size="xs">
                        <FormattedMessage id="module.user.analytics.areas.questions" defaultMessage="Questions" />
                        <> </>
                        <ArrowRight size={10} />
                      </Button>
                    </td>
                  </tr>
                )),
            ]),
        ])}
      </tbody>
    </Table>
  ) : <LoadingPlaceholder />;
}

export default AnalyticsUserAreas;