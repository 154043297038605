import AnalyticsUser from "../components/analytics/AnalyticsUser";

const UserAnalyticsPage = () => {
  
  return (
    <>
      <AnalyticsUser />
    </>
  );
}

export default UserAnalyticsPage;