import type { StatsModel } from "./types";

export const quizSessionModel = {
  id: "quizSession",
  dimensions: {
    email: { type: "STRING" },
    clientId: { type: "STRING" },
    modeId: { type: "STRING" },
    selectionId: { type: "STRING" },
    categoryId: { type: "STRING" },
    date: { type: "DATE" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    score: { type: "INTEGER" },
    time: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "avg-score": { agg: "AVG", metric: "score", type: "FLOAT" },
    "avg-time": { agg: "AVG", metric: "time", type: "FLOAT" },
    "total-time": { agg: "SUM", metric: "time", type: "INTEGER" },
  },
  clustering: ["categoryId", "email"],
} as const satisfies StatsModel;

export const quizQuestionModel = {
  id: "quizQuestion",
  dimensions: {
    email: { type: "STRING" },
    clientId: { type: "STRING" },
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    area: { type: "STRING" },
    quid: { type: "STRING" },
    date: { type: "DATE" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    score: { type: "INTEGER" },
    time: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "avg-score": { agg: "AVG", metric: "score", type: "FLOAT" },
    "median-time": { agg: "MEDIAN", metric: "time", type: "FLOAT" },
    "total-time": { agg: "SUM", metric: "time", type: "INTEGER" },
    completness: { agg: "COMPLETENESS", dimension: "quid" },
  },
  clustering: ["email"],
} as const satisfies StatsModel;

export const quizAnswerModel = {
  id: "quizAnswer",
  dimensions: {
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    quid: { type: "STRING" },
    option: { type: "STRING" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    correct: { type: "BOOLEAN" },
    toggles: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "avg-correct": { agg: "AVG", metric: "correct", type: "FLOAT" },
    "avg-toggles": { agg: "AVG", metric: "toggles", type: "FLOAT" },
  },
  clustering: ["quid"],
} as const satisfies StatsModel;

export const videoSessionModel = {
  id: "videoSession",
  dimensions: {
    email: { type: "STRING" },
    clientId: { type: "STRING" },
    videoId: { type: "STRING" },
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    date: { type: "DATE" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    time: { type: "INTEGER" },
    played: { type: "INTEGER" },
    paused: { type: "INTEGER" },
    age: { type: "INTEGER" },
  },
  queryMetrics: {
    views: { agg: "COUNT" },
    "watch-time": { agg: "SUM", metric: "played", type: "INTEGER" },
    "pause-time": { agg: "SUM", metric: "paused", type: "INTEGER" },
  },
  clustering: ["videoId", "email"],
} as const satisfies StatsModel;

export const videoViewModel = {
  id: "videoView",
  dimensions: {
    email: { type: "STRING" },
    clientId: { type: "STRING" },
    videoId: { type: "STRING" },
    offsetBucket: { type: "INTEGER" },
    date: { type: "DATE" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {},
  queryMetrics: {
    total: { agg: "COUNT" },
    completness: { agg: "COMPLETENESS", dimension: "videoId, offsetBucket" },
  },
  clustering: ["videoId", "email"],
} as const satisfies StatsModel;
