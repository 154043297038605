import { FunctionComponent } from "react"

const LoadingPlaceholder: FunctionComponent = () => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card mt-5">
            <div className="card-body">
              <h5 className="card-title">
                Loading...
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadingPlaceholder;