import { IsArray, IsString, Matches, ValidateNested } from "class-validator";
import { Type } from "class-transformer";

import { Topic } from "./topic";

export class Category {
  @Matches(/^[a-z\-]+$/)
  categoryId: string;
  @IsString()
  title: string;
}

export class CategoryWithTopics extends Category {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Topic)
  topics: Array<Topic>;
}
