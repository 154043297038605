import { Buffer } from "buffer";
import axios from "axios";
import moment from "moment";
import pako from "pako";

import { QuizReleaseConfig } from "../types";
import ApiClient from "./apiClient";
import LocalDatabase from "./localDatabase";
import UserQuestionModel from "../modules/quiz/helpers/UserQuestionModel";
import { QuizLogEntry, QuizLogSelection } from "./types";
import StatsClient from "./statsClient";
import { User, quizAnswerModel, quizQuestionModel } from "@namedicinu/internal-types";

const CURRENT_LOG_VERSION = 1;

export default class QuizClient {
  constructor(
    private apiClient: ApiClient,
    private statsClient: StatsClient,
    private localDatabase: LocalDatabase,
  ) {}

  async getQuizRelease(categoryId: string): Promise<QuizReleaseConfig> {
    const cachedRelease = await this.localDatabase.getQuizReleaseOptional(categoryId);
    try {
      const currentRelease = await this.apiClient.getQuizRelease(categoryId);
      if (cachedRelease) {
        if (cachedRelease.quizReleaseId == currentRelease.quizReleaseId) {
          return cachedRelease;
        }
      }

      const res = await axios.get<ArrayBuffer>(
        `${import.meta.env.VITE_PUBLIC_BUCKET_URL}quiz/${currentRelease.quizReleaseId}.json.gz.aes`,
        {
          responseType: "arraybuffer",
        },
      );
      const encrypted = res.data;

      const [ivStr, keyStr] = Object.entries(currentRelease.playbackKeys)[0]!;
      const iv = Buffer.from(ivStr, "hex");
      const key = await crypto.subtle.importKey("raw", Buffer.from(keyStr, "hex"), "AES-CBC", false, ["decrypt"]);

      const decrypted = await crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, encrypted);
      const decompressed = Buffer.from(pako.inflate(decrypted));
      const release = JSON.parse(decompressed.toString("utf-8")) as QuizReleaseConfig;

      await this.localDatabase.storeQuizRelease(release);
      return release;
    } catch (e) {
      console.error(e);
      // fallback to local database
      if (!cachedRelease) {
        throw new Error("Unable to load quiz release");
      }
      console.warn("Using offline mode");
      return cachedRelease;
    }
  }

  async getQuizReleases(categoryIds: string[]): Promise<{ [categoryId: string]: QuizReleaseConfig }> {
    const releases: { [categoryId: string]: QuizReleaseConfig } = {};
    for (const categoryId of categoryIds) {
      releases[categoryId] = await this.getQuizRelease(categoryId);
    }
    return releases;
  }

  async getUserQuestionModel(
    user: User | undefined,
    categoryId: string,
    topics: { topicId: string; questions: number }[],
  ): Promise<UserQuestionModel> {
    let allData: { topicId: string; questions: number; model: Array<number> | undefined }[];

    if (user) {
      // TODO support multiple users per device
      const cachedUserQuestionModel = await this.localDatabase.getUserQuestionModelOptional(user.email, categoryId);
      if (
        cachedUserQuestionModel &&
        moment.unix(cachedUserQuestionModel.lastRefresh).add(48, "hours").isAfter(moment())
      ) {
        allData = topics.map((topic) => {
          const modelData = cachedUserQuestionModel.topics[topic.topicId];
          return {
            ...topic,
            model: modelData,
          };
        });
      } else {
        allData = await Promise.all(
          topics.map(async (topic) => {
            const serverModelData = await this.apiClient.getUserQuestionModel(categoryId, topic.topicId);
            const modelData = serverModelData.affinity || undefined;
            return {
              ...topic,
              model: modelData,
            };
          }),
        );

        const newlocalTopics: Record<string, Array<number>> = {};
        for (const { topicId, model } of allData) {
          if (model) {
            newlocalTopics[topicId] = model;
          }
        }
        await this.localDatabase.storeUserQuestionModel({
          userId: user.email,
          categoryId,
          lastRefresh: moment().unix(),
          lastLocalUpdate: moment().unix(),
          topics: newlocalTopics,
        });
      }
    } else {
      allData = topics.map((topic) => ({ ...topic, model: undefined }));
    }

    return new UserQuestionModel(allData);
  }

  async startSession(user: User, localSessionId: string, selection: QuizLogSelection): Promise<void> {
    await this.localDatabase.storeQuizSession({
      userId: user.email,
      logVersion: CURRENT_LOG_VERSION,
      localSessionId,
      selection,
    });

    // await this.statsClient.storeLocalStats(
    //   quizSessionModel,
    //   [{
    //     email: user.email,
    //     clientId: "local-placeholder",
    //     modeId: selection.modeId,
    //     selectionId: `local-${guidFrom(selection)}`,
    //     categoryId: selection.categoryId,
    //     date: moment().utc().startOf("day"),
    //     hour: moment().utc().hour(),
    //     timestamp: moment().utc(),
    //     score: ,
    //     time: ,
    //   }],
    // );
  }

  async storeLogEntry(user: User, localSessionId: string, entry: QuizLogEntry): Promise<void> {
    await this.localDatabase.storeQuizLogEntry({
      localSessionId,
      entry,
    });

    await this.statsClient.storeLocalStats(user, quizQuestionModel, [
      {
        email: user.email,
        clientId: "local-placeholder",
        categoryId: entry.categoryId,
        topicId: entry.topicId,
        area: entry.area,
        quid: entry.quid,
        date: moment(entry.timestamp).utc().startOf("day"),
        hour: moment(entry.timestamp).utc().hour(),
        timestamp: moment(entry.timestamp).utc(),
        score: entry.score,
        time: moment.duration(entry.time).asSeconds(),
      },
    ]);
    await this.statsClient.storeLocalStats(
      user,
      quizAnswerModel,
      entry.options.map((option) => ({
        categoryId: entry.categoryId,
        topicId: entry.topicId,
        quid: entry.quid,
        option: option.option,
        timestamp: moment(entry.timestamp).utc(),
        correct: option.correct,
        toggles: option.toggles,
      })),
    );
  }

  async storeLogEntries(localSessionId: string, entries: Array<QuizLogEntry>): Promise<void> {
    await this.localDatabase.storeQuizLogEntries(entries.map((entry) => ({ localSessionId, entry })));
  }

  async offloadLogs(user: User, currentLocalSession: string | undefined): Promise<void> {
    const sessions = await this.localDatabase.getQuizSessions(user.email);
    for (const session of sessions) {
      if (session.localSessionId === currentLocalSession) {
        continue;
      }
      try {
        const entries = await this.localDatabase.getQuizLogEntries(session.localSessionId);
        const quizLogRequest = {
          logVersion: session.logVersion || 0,
          quizSessionId: session.localSessionId,
          selection: {
            ...session.selection, // TODO remove defaults
            shuffleAnswers: session.selection.shuffleAnswers ?? true,
            singleChoice: session.selection.singleChoice ?? false,
          },
          entries: entries.map((entry) => entry.entry),
        };

        await this.apiClient.submitQuizLog(quizLogRequest);

        await this.localDatabase.clearQuizLog(session.localSessionId);
      } catch (e) {
        console.error("Failed to offload logs", e);
      }
    }
  }
}
