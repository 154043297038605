import { FunctionComponent, useState } from "react";
import { Button, Spinner, ButtonProps } from "reactstrap";
import { CheckCircle, XCircle } from "react-feather";
import { handleError } from "../helpers/error";

type AsyncButtonState = "idle" | "pending" | "success" | "error";

type AsyncButtonProps = Exclude<ButtonProps, "onClick"> & {
  onClick: () => Promise<void>,
  clearStateAfter?: number,
};

const AsyncButton: FunctionComponent<AsyncButtonProps> = ({
  children, onClick, clearStateAfter, ...props
}) => {
  const [state, setState] = useState<AsyncButtonState>("idle");

  const handleClick = () => {
    setState("pending");
    onClick()
    .then(() => {
      setState("success");
    })
    .catch(e => {
      setState("error");
      handleError(e);
    })
    .finally(() => {
      setTimeout(() => {
        setState("idle");
      }, clearStateAfter || 5000);
    });
  };

  return (
    <Button
      {...props}
      onClick={handleClick}
      color={state == "success" ? "success" : state == "error" ? "danger" : props.color}
      disabled={props.disabled ? true : state != "idle"}
    >
      {state == "pending" && <Spinner size="sm" />}
      {state == "success" && <CheckCircle size={20} />}
      {state == "error" && <XCircle size={20} />}
      <> </>
      {children}
    </Button>
  );
};

export default AsyncButton;