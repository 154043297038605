export default class UserQuestionModel {
  private questionAffinities: Record<string, Array<number>> = {};

  constructor(topics: { topicId: string; questions: number; model?: Array<number> | undefined }[]) {
    topics.forEach((topic) => {
      if (topic.model) {
        this.questionAffinities[topic.topicId] = topic.model;
      } else {
        this.questionAffinities[topic.topicId] = new Array(topic.questions).fill(2.0);
      }
    });
  }

  getQuestionAffinity(topicId: string, questionNumber: number): number {
    const model = this.questionAffinities[topicId];
    if (!model) {
      throw new Error(`Topic ${topicId} not found`);
    }
    if (questionNumber > model.length) {
      throw new Error(`Question ${questionNumber} not found in topic ${topicId}`);
    }
    return this.questionAffinities[topicId]![questionNumber - 1]!;
  }

  //TODO applyAnswers locally..
}
