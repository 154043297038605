import { createContext, ReactNode, FunctionComponent, useState, useEffect } from "react";

import { User } from "@namedicinu/internal-types";

import { handleError } from "../../../helpers/error";
import useApp from "../../../hooks/useApp";

export type LectorScopeType = "global" | "students" | "study-group";

export type LectorType = {
  scope: LectorScopeType,
  setScope: (scope: LectorScopeType) => void,
  students?: User[]|undefined,
  selectedStudents?: User[]|undefined,
}

const LectorContext = createContext<LectorType|undefined>(undefined);

export const LectorProvider: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const { user, apiClient } = useApp();
  const [scope, setScope] = useState<LectorScopeType>("global");
  const [students, setStudents] = useState<User[]>();
  
  useEffect(() => {
    if(user?.isLector()) {
      apiClient.getLectorStudents().then(setStudents).catch(handleError);
    } else {
      setStudents(undefined);
    }
  }, [user?.isLector(), apiClient]);

  const selectedStudents = (
    scope == "students" ? students :
    scope == "study-group" ? students/* TODO .filter(s => s.studyGroup)*/ :
    undefined
  );

  return (
    <LectorContext.Provider
      value={{
        scope,
        setScope,
        students,
        selectedStudents,
      }}
    >
      {children}
    </LectorContext.Provider>
  );
};

export default LectorContext;
