import { createContext, ReactNode, FunctionComponent, useState, useEffect, useCallback } from "react";
import { Auth, onAuthStateChanged, User as AuthUser } from "firebase/auth";
import { Analytics } from "firebase/analytics";
import { FirebaseStorage } from "firebase/storage";
import usePrefersColorScheme from 'use-prefers-color-scheme';

import { User, UserContent, UserNotification } from "@namedicinu/internal-types";

import { handleError } from "../helpers/error";
import ApiClient from '../clients/apiClient';
import LocalDatabase from "../clients/localDatabase";
import QuizClient from "../clients/quizClient";
import VideoClient from "../clients/videoClient";
import SchemaClient from "../clients/schemaClient";
import AppConfigClient from "../clients/appConfigClient";
import StorageClient from "../clients/storageClient";
import StatsClient from "../clients/statsClient";
import WebAssetTitleClient from "../clients/webAssetTitleClient";

export type AppParams = {
  auth: Auth;
  analytics: Analytics;
  storageSource: FirebaseStorage;
  storagePublic: FirebaseStorage;
  apiClient: ApiClient,
  storageClient: StorageClient,
  localDatabase: LocalDatabase,
  quizClient: QuizClient,
  videoClient: VideoClient,
  schemaClient: SchemaClient,
  appConfigClient: AppConfigClient,
  statsClient: StatsClient,
  webAssetTitleClient: WebAssetTitleClient,
};

export type AppType = AppParams & {
  user: User|null|undefined;
  userPic: string|undefined;
  content: UserContent;
  notifications: UserNotification[]|undefined;
  markNotificationsAcknowledged: () => void;
  currentQuizSessionId: string|undefined;
  setCurrentQuizSessionId: (id: string|undefined) => void;
  currentVideoSessionId: string|undefined;
  setCurrentVideoSessionId: (id: string|undefined) => void;
  storedColorScheme: "light"|"dark"|undefined;
  colorScheme: "light"|"dark";
  nextColorScheme: () => void;
}

const AppContext = createContext<AppType|undefined>(undefined);

export const AppProvider: FunctionComponent<AppParams & { children: ReactNode }> = ({
  auth,
  analytics,
  storageSource,
  storagePublic,
  apiClient,
  storageClient,
  localDatabase,
  quizClient,
  videoClient,
  schemaClient,
  appConfigClient,
  statsClient,
  webAssetTitleClient,
  children,
}) => {
  const [user, setUser] = useState<User|null|undefined>();
  const [userPic, setUserPic] = useState<string>();
  const [content, setContent] = useState<UserContent>({
    categories: [],
    tracts: [],
  });
  const [notifications, setNotifications] = useState<UserNotification[]>();

  const [currentQuizSessionId, setCurrentQuizSessionId] = useState<string>();
  const [currentVideoSessionId, setCurrentVideoSessionId] = useState<string>();
  const [initialOffload, setInitialOffload] = useState(false);

  const [colorScheme, setColorScheme] = useState<"light"|"dark">();
  const prefersColorScheme = usePrefersColorScheme();
  const activeColorScheme = (
    colorScheme ? colorScheme :
    prefersColorScheme != "no-preference" ? prefersColorScheme :
    "light"
  );

  useEffect(() => {
    const loadUser = async (authUser: AuthUser|null) => {
      await apiClient.connectAuth(authUser);
      await storageClient.connectAuth();
      if(authUser) {
        const init = await apiClient.getUserInitMe();
        setUser(init.user);
        setContent(init.content);
        setNotifications(init.notifications);
        const userPic = authUser.photoURL;
        setUserPic(userPic || undefined);
      } else {
        setUser(null);
        setContent({ categories: [], tracts: [] });
        setNotifications([]);
        setUserPic(undefined);
      }
    }
    onAuthStateChanged(auth, (authUser) => {
      loadUser(authUser).catch(handleError);
    });
  }, [auth]);

  // initial offload
  useEffect(() => {
    if(user && !initialOffload) {
      const timeout = setTimeout(() => {
        quizClient.offloadLogs(user, currentQuizSessionId).catch(handleError);
        videoClient.offloadLogs(user, currentVideoSessionId).catch(handleError);
        statsClient.clearOldStatsEntries(user).catch(handleError);
        setInitialOffload(true);
      }, 1000 * 3);
      return () => clearTimeout(timeout);
    }
    return;
  }, [user, initialOffload, currentQuizSessionId, currentVideoSessionId]);

  // successive offloads just about every minute
  useEffect(() => {
    if(user) {
      const interval = setInterval(() => {
        quizClient.offloadLogs(user, currentQuizSessionId).catch(handleError);
        videoClient.offloadLogs(user, currentVideoSessionId).catch(handleError);
      }, 1000 * 69);
      return () => clearInterval(interval);
    }
    return;
  }, [user, currentQuizSessionId, currentVideoSessionId]);

  useEffect(() => {
    const localColorScheme = localStorage.getItem("colorScheme")
    if (localColorScheme == "dark") setColorScheme("dark");
    if (localColorScheme == "light") setColorScheme("light");
    return;
  }, []);

  const pickColorScheme = useCallback((colorScheme: "light"|"dark"|undefined) => {
    setColorScheme(colorScheme);
    if (colorScheme) {
      localStorage.setItem("colorScheme", colorScheme);
    } else {
      localStorage.removeItem("colorScheme");
    }
  }, []);

  const nextColorScheme = useCallback(() => {
    if (prefersColorScheme == "dark") {
      if (colorScheme == undefined) pickColorScheme("light");
      if (colorScheme == "light") pickColorScheme("dark");
      if (colorScheme == "dark") pickColorScheme(undefined);
    } else {
      if (colorScheme == undefined) pickColorScheme("dark");
      if (colorScheme == "dark") pickColorScheme("light");
      if (colorScheme == "light") pickColorScheme(undefined);
    }
  }, [colorScheme, prefersColorScheme]);

  const markNotificationsAcknowledged = useCallback(() => {
    apiClient.acknowledgeAllNotifications().then(() => setNotifications([])).catch(handleError);
  }, [apiClient, handleError]);

  return (
    <AppContext.Provider
      value={{
        auth,
        analytics,
        storageSource,
        storagePublic,
        apiClient,
        storageClient,
        localDatabase,
        quizClient,
        videoClient,
        schemaClient,
        appConfigClient,
        statsClient,
        webAssetTitleClient,

        user,
        userPic,
        content,
        notifications,
        markNotificationsAcknowledged,
        currentQuizSessionId,
        setCurrentQuizSessionId,
        currentVideoSessionId,
        setCurrentVideoSessionId,
        storedColorScheme: colorScheme,
        colorScheme: activeColorScheme,
        nextColorScheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
