import { LocaleType, Localized } from "@namedicinu/internal-types";
import { WebEntryType } from "@namedicinu/web-types";

import ApiClient from "./apiClient";

export default class WebAssetTitleClient {
  titleCache: Map<string, Promise<string>> = new Map();

  constructor(private apiClient: ApiClient) {}

  async _getWebAssetTitle(id: string, locale: LocaleType): Promise<string> {
    const entry = await this.apiClient.getWebAsset(id);
    if ("title" in entry) {
      if (entry.title instanceof Localized) {
        const localizedTitle = entry.title[locale];
        if (localizedTitle) {
          return localizedTitle;
        }
      }
    }
    return `?:${id}`;
  }

  async getWebAssetTitle(id: string, locale: LocaleType): Promise<string> {
    const key = `asset:${id}:${locale}`;
    if (!this.titleCache.has(key)) {
      this.titleCache.set(key, this._getWebAssetTitle(id, locale));
    }
    return await this.titleCache.get(key)!;
  }

  async _getWebEntryTitle(type: WebEntryType, id: string, locale: LocaleType): Promise<string> {
    const entry = await this.apiClient.getWebEntry(type, id);
    if ("title" in entry) {
      if (typeof entry.title == "string") {
        return entry.title;
      } else if (entry.title instanceof Localized) {
        const localizedTitle = entry.title[locale];
        if (localizedTitle) {
          return localizedTitle;
        }
      }
    }
    return `?${type}:${id}`;
  }

  async getWebEntryTitle(type: WebEntryType, id: string, locale: LocaleType): Promise<string> {
    const key = `entry:${type}:${id}:${locale}`;
    if (!this.titleCache.has(key)) {
      this.titleCache.set(key, this._getWebEntryTitle(type, id, locale));
    }
    return await this.titleCache.get(key)!;
  }
}
