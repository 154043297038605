import axios from "axios";
import { toast } from "react-toastify";

const showErrorOverlay = (err: unknown) => {
  const ErrorOverlay = customElements.get("vite-error-overlay");
  if (!ErrorOverlay) {
    return;
  }
  const overlay = new ErrorOverlay(err);
  document.body.appendChild(overlay);
};

export const handleError = (error: unknown) => {
  console.error(error);
  if (import.meta.env.DEV) {
    showErrorOverlay({ message: errorMessage(error) });
  } else {
    toast.error(errorMessage(error), { autoClose: false });
  }
};

export const errorMessage = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    error = error.response?.data || error;
  }
  if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error == "object" && typeof (error as any).message == "string") {
    return (error as any).message;
  } else {
    return "Unknown error occurred";
  }
};

// catch unhanded errors
window.addEventListener("error", handleError);
window.addEventListener("unhandledrejection", ({ reason }) => handleError(reason));
