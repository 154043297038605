import { Fragment, FunctionComponent, ReactNode } from "react";
import { useIntl } from "react-intl";
import {
  Card, CardBody, Col,
  Container, Nav, NavItem, Row, NavLink, TabContent, TabPane,
} from "reactstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import Breadcrumb from "../../../components/common/Breadcrumb";
import { LectorProvider } from "../contexts/LectorContext";

export type ManageWrapperType = {
  tabs: Array<{
    id: string;
    name: ReactNode;
    element?: ReactNode;
  }>,
};

const UserWrapper: FunctionComponent<ManageWrapperType> = ({ tabs }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const activeTab = tabs.find(
    (tab) => tab.id == "index" ? pathname == "/lector" : pathname.startsWith("/lector/"+tab.id)
  );

  return (
    <>
      <Breadcrumb
        parent={intl.formatMessage({id: "page.index.title", defaultMessage: "Namedicinu"})}
        title={intl.formatMessage({id: "module.lector.title", defaultMessage: "Lector"})}
      />
      <LectorProvider>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody className="p-3">
                  <Row>
                    <Col sm="2" className="tabs-responsive-side pt-3">
                      <Nav className="flex-column nav-pills border-tab-primary nav-left">
                        {tabs.map((tab) => (
                          tab.element ? (
                            <NavItem key={tab.id}>
                              <NavLink
                                tag="button"
                                className={clsx({"active": activeTab?.id == tab.id})}
                                onClick={() => navigate(tab.id == "index" ? `/lector` : `/lector/${tab.id}`, { replace: true })}
                              >
                                {tab.name}
                              </NavLink>
                            </NavItem>
                          ) : (
                            <Fragment key={tab.id}>
                              {tab.name}
                            </Fragment>
                          )
                        ))}
                      </Nav>
                    </Col>
                    <Col sm="10">
                      <TabContent>
                        <TabPane>
                          <Outlet />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LectorProvider>
    </>
  );
}

export default UserWrapper;